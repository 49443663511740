.MuiSnackbarContent-root {
  font-family: 'Public Sans' !important;
  width: auto !important;
  padding: 0px 10px !important;
  border-radius: 8px !important;
  box-shadow: none !important;
  color: #000000 !important;
}
.MuiSnackbar-anchorOriginTopCenter {
  top: 70px !important;
}
.toast-success .MuiSnackbarContent-root {
  background-color: #d7f3e4 !important;
  border: 1px solid #06a650;
}
.toast-error .MuiSnackbarContent-root {
  background-color: #fff0f0 !important;
  border: 1px solid #e31a1c;
}
.toast-success .close-btn {
  color: #06a650 !important;
}
.toast-error .close-btn {
  color: #e31a1c !important;
}
.toast-info .MuiSnackbarContent-root {
  background-color: #fff8ec !important;
  border: 1px solid #ff730e;
}
.toast-info .close-btn {
  color: #ff730e !important;
}
.toast-icon {
  margin-right: 5px;
}
.toast-icon svg {
  width: 24px;
  height: 24px;
  vertical-align: middle;
}

.MuiBox-root .placement {
  &-circleIcon {
    position: relative;
    top: 1px;
  }
  &-tab-header-section {
    margin: 0px -18px 10px -18px;
    border-bottom: 1px solid #e9e9e9;
  }
  &-dropdown-label {
    color: #707070;
    font-weight: 400;
    margin-top: 4px;
    margin-right: 10px;
  }
  &-dropdown {
    font-family: 'Public Sans', sans-serif !important;
    padding-left: 1px !important;
    min-width: 160px !important;
    font-weight: 400 !important;
    color: #000;
    padding: 0px !important;
  }
  &-dropdown-menu {
    padding: 5px;
    font-family: 'Public Sans', sans-serif !important;
    font-weight: 400 !important;
    color: #000;
  }
  &-badge {
    font-family: 'Public Sans', sans-serif !important;
    padding: 4px 20px;
    font-weight: 400;
    display: inline-block;
    border-radius: 12px;
    margin: 0px !important;
  }
}
.withPlacement {
  margin-bottom: 0px !important;
}
.withoutPlacement {
  margin-bottom: 20px !important;
}

.nameGrid {
  display: flex;
  direction: row;
  align-items: center;
  gap: 1;
}
.calenderGrid {
  display: flex;
  margin-left: 2px;
  justify-content: flex-start;
}
.noPlacementDetailsItems {
  margin: auto !important;
  display: flex !important;
  flex-direction: column !important;
}
.noPlacementsDetailsItemsText {
  text-align: center;
}

.noPlacementText {
  color: #2a70ea;
  text-align: center;
  font-family: 'Public Sans', sans-serif !important;
  font-weight: 700;
}

.holeTight {
  color: #4a4a4a;
  font-family: 'Public Sans', sans-serif !important;
  font-weight: 400 !important;
}

.placementText {
  color: #212121;
  font-family: 'Public Sans', sans-serif !important;
  font-weight: 600 !important;
  padding-top: 1px;
}

.additionalDetails {
  color: #2a70ea;
  font-family: 'Public Sans', sans-serif !important;
  font-weight: 600 !important;
}

.WeeklyText {
  color: #2a70ea;
  font-family: 'Public Sans', sans-serif !important;
  font-weight: 400 !important;
}
.emailLabel {
  display: inline-block;
  position: relative !important;
  top: -10px;
}
.emailIcon {
  margin-left: 15px;
}
.status-icon {
  margin-right: 5px !important;
}

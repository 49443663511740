.candidate-update {
  &-name {
    color: #fff !important;
    font-style: normal !important;
    font-weight: 400 !important;
    z-index: 1;
    position: relative;
  }
  &-info {
    color: #fff !important;
    font-style: normal;
    font-weight: 300 !important;
    margin-top: 11px !important;
    z-index: 1;
    position: relative;

    &-text {
      color: #404040;
      margin-top: 1rem !important;
      text-align: left;
      font-style: normal;
      font-weight: 300 !important;
    }
  }

  &-profile {
    background: #000;
    padding-left: 33px;
    padding-top: 36px;
    padding-bottom: 30px;
    padding-right: 20px;
    color: #fff;
    position: relative;

    background-image: url('../../images/Home_card.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    flex: 1;
  }
  &-card-title {
    color: #131416;
    font-style: normal;
    font-weight: 400 !important;
  }
}

.update-profile-button {
  margin-top: 4.5rem !important;
  border-radius: 0 !important;
  z-index: 1 !important;
}
.candidate-card-text-header {
  display: flex;
  gap: 6px;
  align-items: center;
}
.candidate-card-button {
  display: flex;
  justify-content: left;
  margin-top: 76px;
}
.candidate-avatar-icon {
  margin-top: -12px;
  z-index: 1;
}
.candidate-edit-icon {
  position: absolute;
  right: 0;
}
.show-more {
  color: #707070;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
}

@media screen and (max-width: 900px) {
  .candidate-update-profile {
    background-image: url('');
  }
}

.dialogTitle {
  color: #2a70ea;
  font-family: 'Poppins', sans-serif !important;
  /* font-size: 1.5rem; */
  font-style: normal;
  font-weight: 600;
}

.dialogContent {
  overflow: auto;
  height: 22.5rem;
  padding: 0px;
  margin-right: 1.25rem;
}

.dialogContent::-webkit-scrollbar {
  width: 0.75rem;
  height: 5.25rem;
  background-color: #f0f0f0;
}

.dialogContent::-webkit-scrollbar-thumb {
  background-color: #2a70ea;
  border-radius: 2.5rem;
}

.dialogContent::-webkit-scrollbar-track {
  background-color: #f0f0f0;
  border-radius: 2.5rem;
}
.confirmButton {
  display: inline-flex !important;
  /* padding: 0.6rem 0.85rem !important; */
  justify-content: center !important;
  align-items: center !important;
  border-radius: 0.25rem !important;
  background: #2a70ea;
  color: #fff !important;
  font-family: 'Public Sans', sans-serif !important;
  /* font-size: 1rem !important; */
  font-style: normal;
  font-weight: 400 !important;
  text-transform: none !important;
  min-width: auto;
  width: 100%;
  text-wrap: nowrap;
}
.cancelButton {
  display: inline-flex !important;
  /* padding: 0.6rem 1rem 0.55rem 1.05rem !important; */
  justify-content: center;
  align-items: center;
  border-radius: 0.25rem !important;
  border: 0.063rem solid #d9d9d9 !important;
  color: #212121 !important;
  font-family: 'Public Sans', sans-serif !important;
  /* font-size: 1rem !important; */
  font-style: normal;
  font-weight: 300 !important;
  margin-right: 0.625rem !important;
  text-transform: none !important;
  min-width: auto;
  width: 100%;
}

.scrollbar {
  overflow: auto;
  height: 22.5rem;
  padding: 0px;
  margin-right: 1.25rem;
  overflow-x: hidden;
}

@media screen and (max-width: 768px) {
  .scrollbar {
    overflow-x: auto;
  }
}

.scrollbar::-webkit-scrollbar {
  width: 0.6rem;
  height: 5.25rem;
  background-color: #f0f0f0;
}
@media screen and (max-width: 768px) {
  .scrollbar::-webkit-scrollbar {
    height: 0.25rem;
  }
}

.scrollbar::-webkit-scrollbar-thumb {
  background-color: #2a70ea;
  border-radius: 2.5rem;
}

.scrollbar::-webkit-scrollbar-track {
  background-color: #f0f0f0;
  border-radius: 2.5rem;
}

.dialogAction {
  justify-content: space-between !important;
  color: #000;
  text-align: justify;
  font-family: 'Public Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
}

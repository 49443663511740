.coachbot-toggle-btn {
  position: fixed;
  cursor: pointer;
  z-index: 30;
}
.career-details-main-header {
  display: flex;
  align-items: center;
  .career-details-header {
    display: flex;
    position: absolute;
    gap: 1rem;
  }
}

.coachbot-container {
  width: 450px;
  max-width: 100%;
  box-shadow: 2px 2px 7px 0 rgba(0, 0, 0, 0.2);
  background: #fff;
  position: fixed;
  z-index: 20;
  bottom: 50px;
  border-radius: 5px;
  transition:
    width 0.5s ease,
    height 0.9s ease;
  .coachbot-header {
    background: #000;
    color: #fff;
    padding: 15px;
    border-radius: 5px 5px 0 0;

    .coachbot-options {
      gap: 10px;
      text-align: center;
      cursor: pointer;
    }
  }
  .coachbot-sub-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .coachbot-sub-header > div {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .coachbot-admin {
    width: 32px;
    height: 32px;
    background: #fff;
    border-radius: 50%;
    color: #000;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: normal;
    position: relative;
    .coachbot-admin-status {
      width: 9px;
      height: 9px;
      background: #6ae270;
      border-radius: 50%;
      position: absolute;
      top: 0;
      right: 0;
    }
  }

  .coachbot-body {
    // height: 360px;
    overflow: auto;
    scrollbar-width: thin;
    padding: 15px;

    .chat-message-container {
      display: flex;
      gap: 10px;
      margin-top: 8px;
      .bot-icon {
        width: 26px;
        height: 26px;
        background: #f0f0f0;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
      }
    }
    .user-message-container {
      justify-content: flex-end;
      .chat-time {
        text-align: right;
      }
    }
    .chat-message {
      margin-bottom: 0;
    }
    .chat-time {
      color: #000;
      font-weight: 400;
      margin-top: 3px;
      margin-right: 5px;
    }
    .bot-message {
      background-color: #e7e7e7;
      border-radius: 0px 30px 30px 30px;
      align-self: flex-start;
      display: inline-block;
      padding: 15px;
      .chat-points {
        list-style: none;
        margin: 5px 0px;
        padding: 0;
        a {
          color: #0950cd;
          text-decoration: underline;
        }
      }
    }
    .user-message {
      background-color: #0950cd;
      color: #fff;
      border-radius: 30px 30px 0px 30px;
      padding: 15px;
      align-self: flex-end;
    }
  }
  .coachbot-suggestions {
    padding: 10px 0px 10px 20px;
    ul {
      list-style-type: none;
      padding: 0;
      margin: 0;
      display: flex;
      align-items: center;
      gap: 10px;
      flex-wrap: nowrap;
      width: 100%;
      overflow: auto;
      scrollbar-width: thin;
      white-space: nowrap;
      li {
        padding: 10px;
        border: #0950cd 1px solid;
        border-radius: 30px;
        color: #0950cd;
        cursor: pointer;
        &:hover {
          background: #0950cd;
          color: #fff;
        }
      }
    }
  }
  .coachbot-input-container {
    padding: 15px 20px;
    border-top: #ececec 1px solid;
    .coachbot-input {
      position: relative;
      background: #fafafa;
      border-radius: 4px;
      .coachbot-send-button {
        min-width: 32px;
        min-height: 32px;
        background: #000;
        color: #fff;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        right: 10px;
        bottom: 10px;
        cursor: pointer;
      }
      .coachbot-send-button:disabled {
        opacity: 0.6;
        cursor: not-allowed;
      }
    }
  }
}
.coachbot-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 1;
}

.typing-dots {
  display: inline-block;
}
.typing-dots span {
  display: inline-block;
  animation: bounce 1.4s infinite;
  line-height: 0;
}
.typing-dots span:nth-child(1) {
  animation-delay: 0s;
}
.typing-dots span:nth-child(2) {
  animation-delay: 0.2s;
}
.typing-dots span:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes bounce {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

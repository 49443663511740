.TPclassSmallText {
  /* font-size: 14px !important; */
  font-weight: 400 !important;
  letter-spacing: normal;
}
.TPclassMediumText {
  /* font-size: 16px !important; */
  font-weight: 400 !important;
  letter-spacing: normal;
}
.TPclassLargeText {
  /* font-size: 24px !important; */
  font-weight: 600 !important;
  letter-spacing: normal;
}

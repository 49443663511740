.tp-dropdown {
  border-color: #d9d9d9;
  color: #686868;
  border-radius: 0.42rem !important;
  font-family: 'Public Sans', sans-serif !important;
  transition:
    color 0.15s ease,
    background-color 0.15s ease,
    border-color 0.15s ease,
    box-shadow 0.15s ease;
  &-menu {
    font-family: 'Public Sans', sans-serif !important;
  }
}

.preference {
  padding: 30px;
  .your-preference {
    color: #212121;
    font-style: normal;
    font-weight: 200;
  }
  .contact-preference-btn {
    border-radius: 4px;
    border: 1px solid #2a70ea;
    background: rgba(42, 112, 234, 0.1);
    display: inline-flex;
    height: '5%';
    padding: 10px 24px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    color: #2a70ea;
    font-style: normal;
    font-weight: 600;
    box-shadow: none;
    &:hover {
      background: rgba(42, 112, 234, 0.1);
      box-shadow: none;
    }
  }
  .date-picker {
    margin-top: 16px;
  }

  .checkbox-label.custom-checkbox {
    .MuiCheckbox-root {
      &:not(.Mui-checked):not(.Mui-disabled) {
        .MuiSvgIcon-root {
          path {
            stroke: whitesmoke;
          }
        }
      }
    }
  }

  .checkbox-label {
    .MuiFormControlLabel-label {
      color: #212121;
      font-style: normal;
      font-weight: 300;
    }
  }
  .enter-values {
    color: #686868;
    font-style: normal;
    font-weight: 300;
    margin-top: 16px;
  }
  .select-days,
  .select-roles,
  .select-currency,
  .select-frequency,
  .select-employment {
    .MuiSelect-select {
      color: #686868;
      font-style: normal;
      font-weight: 300;
    }
  }
  .min-salary,
  .max-salary {
    color: #686868;
    font-style: normal;
    font-weight: 300;
    margin-top: 25px;
  }
}
.notice-period,
.currency,
.minRate,
.maxRate,
.minSalary,
.maxSalary,
.salaryPeriod,
.date-picker,
.custom-select-input-base,
.set-duration {
  color: #686868 !important;
  font-style: normal !important;
  font-weight: 300 !important;
}
.set-duration {
  margin-top: 1rem;
}
.employment {
  color: #212121;
  font-style: normal;
  font-weight: 600 !important;
}
.css-1bbi5il-MuiPaper-root-MuiAutocomplete-paper {
  box-shadow: none !important;
}

.roles-select-button {
  background: transparent !important;
  box-shadow: none !important;
  &:hover {
    background: transparent !important;
    box-shadow: none !important;
  }
}
.arrow-icon {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 1em;
  height: 1em;
  display: inline-block;
  fill: currentColor;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  position: absolute;
  right: 0;
  top: calc(50% - 0.5em);
  pointer-events: none;
  color: rgba(0, 0, 0, 0.54);
}
.custom-select-input-base {
  &.MuiInputBase-root {
    flex-wrap: wrap;
    padding: 5px;
  }

  .MuiSelect-select {
    position: absolute;
  }

  .MuiChip-root {
    z-index: 1;
    border: 1px solid #79747e;
    background-color: #fff;
    border-radius: 80px;
  }
}
.css-qqzfpy-MuiInputBase-root-MuiInput-root::before,
.css-1fd8b26-MuiInputBase-root-MuiInput-root::before,
.css-1uj71qq-MuiInputBase-root-MuiInput-root-MuiSelect-root::before {
  // border-bottom: 1px solid #d9d9d9 !important;
}
.success {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
}
.ratePeriod {
  color: #686868 !important;
}
.role-sought-down-arrow-icon {
  position: absolute;
  right: 0px;
}
.preference-info {
  margin-top: 4px;
  position: relative;
  left: 8px !important;
}

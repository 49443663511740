.evidence {
  &-header {
    color: #212121;
    font-style: normal;
    font-weight: 200 !important;
  }
  &-cross-close-icon {
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
    color: #707070;
  }
  &-name-input {
    color: #303030 !important;
    font-style: normal !important;
    font-weight: 600 !important;
    margin-top: 16px !important;
  }
  &-date-picker {
    margin-top: 16px !important;
  }
  &-upload-file {
    color: #2a70ea;
    font-style: normal;
    font-weight: 600 !important;
  }
  &-upload-info {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  &-dragToUpload {
    color: #000 !important;
    font-style: normal;
    font-weight: 400 !important;
  }
  &-drag-drop-upload {
    border: 1px #2a70ea !important;
    border-radius: 4px;
    padding: 20px;
    text-align: center !important;
    cursor: pointer !important;
    transition: border 0.3s ease-in-out;
    margin-top: 16px;
    position: relative;
    height: 150px;
    background-color: #f5f9ff;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%232A70EA' stroke-width='2' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  }
  &-browse {
    color: #2a70ea !important;
    font-style: normal;
    font-weight: 400 !important;
  }
}

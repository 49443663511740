.headerMain {
  width: 100%;
  flex-shrink: 0;
  background-color: #fff !important;
  box-shadow: none !important;

  &-env {
    color: var(--Alliance, #2a70ea);
    text-align: center;
    font-style: normal !important;
    font-weight: 500 !important;
    position: relative;
  }

  &-logo {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.headerTab {
  color: #707070 !important;
  font-weight: 400 !important;
  background-color: #fff !important;
  box-shadow: none !important;
}
.activeHeaderTab {
  color: #2a70ea !important;
  text-align: center !important;
  font-style: normal;
  font-weight: 500 !important;
  border-radius: 0 !important;
  background-color: #fff !important;
  box-shadow: none !important;
  border-bottom: 1px solid #2a70ea !important;
}
.activeMainHeaderTab {
  color: #2a70ea !important;
  text-align: center !important;
  font-style: normal;
  font-weight: 500 !important;
  border-radius: 0 !important;
  background-color: #fff !important;
  box-shadow: none !important;
  &::after {
    content: '' !important;
    position: absolute !important;
    left: 0;
    right: 0;
    bottom: 1px !important;
    height: 2px !important;
    background-color: #2a70ea;
    border-bottom: 1px solid #2a70ea !important;
  }
}
.logout-popover .MuiPopover-paper {
  width: 5vw !important;
  left: auto !important;
  top: '10%' !important;
  right: 30px;
  text-align: center;
}
.logout-popover .MuiPopover-paper p.MuiTypography-root {
  font-weight: 500;
  color: #707070;
  cursor: pointer;
  padding: 0.7rem 0;
  border-bottom: 1px solid #e9e9e9;
}
.MuiAvatar-root.user-icon-avatar {
  display: inline-flex;
  font-weight: 600;
  background: #707070;
  cursor: pointer;
  margin: 0 0.8rem;
}
.icon-align {
  display: flex;
  align-items: center;
}
@media (max-width: 900px) {
  .logout-popover .MuiPopover-paper {
    width: 9vw !important;
  }
}
@media (max-width: 600px) {
  .logout-popover .MuiPopover-paper {
    width: 12vw !important;
  }
}
@media (max-width: 425px) {
  .logout-popover .MuiPopover-paper {
    width: 16vw !important;
    right: 18px;
  }
  .logout-popover .MuiPopover-paper p.MuiTypography-root {
    padding: 0.5rem 0;
  }
}

.profile {
  &-reuired-details {
    font-family: 'Public Sans', sans-serif !important;
    display: flex;
    justify-content: end;
  }
  &-badge {
    font-family: 'Public Sans', sans-serif !important;
    padding: 4px 8px;
    color: #660000;
    font-weight: 400;
    display: inline-block;
    margin-left: 20px !important;
    background: #fcc;
    border-radius: 12px;
  }
  &-circleIcon {
    position: relative;
    top: 1px;
  }
}
.personalDetailheader {
  font-family: 'Public Sans', sans-serif !important;
  color: #2a70ea;
  font-weight: 600 !important;
  margin-bottom: 10px !important;
}

.infoIcon {
  position: relative;
  top: 3px;
  left: 10px;
}
.msg-class {
  display: flex;
  justify-content: center;
}

.MuiGrid-root .profile-summary {
  padding-left: 33px;
  padding-top: 36px;
  padding-bottom: 30px;
  padding-right: 20px;
  background-image: url('../../../../tandym-web-common/src/images/summary-img.png');
  &-info {
    width: 55%;
  }
}
.char_counter {
  color: #707070;
}
#discard-changes-btn {
  margin-right: 15px;
}
@media screen and (max-width: 900px) {
  .MuiGrid-root .profile-summary {
    background-image: url('');
    background-color: #000;
    &-info {
      width: 100%;
    }
  }
}
@media screen and (max-width: 350px) {
  #discard-changes-btn {
    margin-right: 0px;
    margin-bottom: 15px;
  }
}

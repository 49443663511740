.faq-row {
  border-bottom: 1px solid #d9dbe9;
  padding: 12px 0 12px 0;
  .faq-question {
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #212121;
    cursor: pointer;
    .faq-expand-icon {
      cursor: pointer;
      img {
        width: 19.2px;
        height: 19.2px;
      }
    }
  }
  .faq-answer {
    color: #707070;
    margin-top: 12px;
    font-weight: 400;
    p {
      margin-bottom: 0px;
    }
    ol {
      padding-left: 20px;
      margin-bottom: 0px;
      li {
        margin-top: 5px;
      }
    }
    ul {
      padding-left: 20px;
      margin-bottom: 0px;
      li {
        list-style-type: disc;
        &:not(:last-child) {
          margin-bottom: 10px;
        }
      }
    }
  }
  .faq-expand-text {
    display: none;
  }
}

.expend-all-btn {
  float: right;
  padding-right: 30px;
  margin-top: 20px;
  cursor: pointer;
}

.compliance {
  &-table-status {
    display: flex;
    padding: 6px 10px;
    justify-content: center;
    align-items: center;

    border-radius: 46px;
    border: 1px solid #d5d9eb;
    background: #fff;
    margin-top: 0px !important;
    gap: 10px;
  }
  &-btn {
    min-width: 102px !important;
  }
  &-status {
    font-style: normal;
    font-weight: 500 !important;
    flex-grow: 2;
  }
  &-circle-dot-icon {
    flex-grow: 1;
  }
  &-table-cell {
    border-bottom: 1px solid #eaecf0 !important;
    color: #101828 !important;
    font-style: normal !important;
    font-weight: 500 !important;
  }
  &-header-text {
    color: #707070 !important;
    font-style: normal;
    font-weight: 300 !important;
    letter-spacing: 0.28px !important;
    text-transform: uppercase !important;
  }
  &-table-header-section {
    display: flex;
    align-items: center;
    gap: 5px;
  }
  &-sort-icon {
    cursor: pointer;
  }
  &-download-img {
    border: 1px solid #dddddd;
    padding: 3px;
  }
}
.no-data {
  text-align: center;
  margin-top: 20px !important;
}

@media (min-width: 1300px) {
  .compliance {
    &-table-status {
      width: 46%;
    }
  }
}

.MuiBox-root {
  .learning {
    &-Banner {
      padding: 50px 20px 80px 33px;
      background-image: url('../../images/learning-pathways-image.png');
    }
    &-tandym-coaching {
      padding: 30px 0px 40px 33px;
      background-image: url('../../images/tandym-coach-image.png');
    }

    &-name {
      color: #fff;
      font-weight: 400;
      z-index: 1;
      position: relative;
    }
    &-card-title {
      color: #131416;
      font-weight: 400;
    }
    &-description {
      color: #404040;
      margin-top: 1rem;
      font-weight: 300;
    }
    &-card-sub-title {
      color: #707070;
      font-weight: 300;
      list-style: 11px;
    }
    & a {
      color: #2a70ea;
      font-weight: 700;
      text-decoration: none;
    }
    .never-placed-banner-info {
      display: inline-block;
      color: antiquewhite;
      font-weight: 300;
    }
  }
  .learning-card-number-text {
    color: #2a70ea;
    font-weight: 700;
    padding-top: 1rem;
    &-description {
      color: #404040;
      margin-top: 1rem;
      font-weight: 300;
    }
  }
  .learning-card-text-header {
    display: flex;
    align-items: center;
    color: #131416;
    font-weight: 400;
  }
  .learning-card-number-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .learning-card-box {
    border: 1px solid #ececec;
    background-color: #fafafa;
    padding: 24px;
  }
  .learning-card-content {
    display: flex;
    align-items: center;
    gap: 20px;
  }

  .collaborativeCoaching-title {
    color: #212121;
    font-weight: 400;
  }

  .learning-resource-container-box {
    border: 1px solid #ececec;
    background-color: #fafafa;
    padding: 17px;
  }
  .learning-resource-description {
    color: #404040;
    font-weight: 300;
  }
  .learning-card-sub-heading {
    color: #2a70ea;
    font-weight: 400;
    margin-top: 0.7rem;
    &-point {
      font-weight: 400;
      color: #707070;
      font-style: italic;
    }
  }
  .width-md {
    width: 65%;
  }
}

.coaching-btn svg {
  margin-left: 8px;
}

.tandym-coach-feature-box {
  border: 1px solid #ececec;
  background-color: #fafafa;
  padding: 1rem;
  & .tandym-coach-feature-title {
    color: #131416;
    font-weight: 500;
    margin-bottom: 0.7rem;
  }
  & .tandym-coach-feature-description {
    color: #404040;
    font-weight: 400;
    margin-bottom: 0.6rem;
    & span {
      color: #000;
      font-weight: 600;
    }
  }
}

@media screen and (max-width: 900px) {
  .MuiBox-root {
    .learning {
      &-Banner {
        background-image: url('');
        background-color: #000;
      }
    }
  }
}

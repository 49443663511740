.skillsHeader {
  font-family: 'Public Sans', sans-serif !important;
  font-weight: 200 !important;
}
.tableGrid {
  margin: 0 -20px 0 -20px !important;
}
.tableGrid .MuiPaper-root {
  box-shadow: none !important;
}
.tableGrid .MuiTableRow-root:first-child th {
  border-top: 1px solid #e9e9e9 !important;
}
.tableGrid th,
.tableGrid td,
.tableGrid .MuiTableRow-root:last-child th,
.tableGrid .MuiTableRow-root:last-child td {
  border-bottom: 1px solid #e9e9e9 !important;
  padding: 15px 20px !important;
}
.descriptionText {
  font-family: 'Public Sans', sans-serif !important;
  font-weight: 200 !important;
}
.noDataAvailable {
  font-family: 'Public Sans', sans-serif !important;
}
.plusIcon {
  background: linear-gradient(180deg, #2a70ea 0%, #2a70ea 100%);
  width: 32px;
  height: 32px;
  flex-shrink: 0;
  align-items: center;
  display: flex;
  justify-content: center;
  border-radius: 3.692px;
  padding-right: 5;
}

.css-1ebprri {
  .top-left-text-2 {
    position: relative;
    top: -4rem;
    padding-left: 540px;
    display: flex;
  }
  .top-left-text-3 {
    position: relative;
    top: -4rem;
    padding-left: 540px;
    display: flex;
  }
  .top-left-text-4 {
    position: relative;
    top: -4rem;
    padding-left: 540px;
    display: flex;
  }
}

.candidate-info {
  border-radius: 8px;
  background: #fff;
  flex-shrink: 0;
  padding-left: 0px;

  &-first {
    display: flex;
    height: 150px;
    justify-content: flex-end;
    align-items: center;
    flex-shrink: 0;
    background: #000;
    position: relative;
    .edit-icon {
      position: absolute;
      top: 6px;
      right: 6px;
      cursor: pointer;
    }
  }
  .avatar-icon {
    position: relative;
    margin-top: -28px;
    margin-left: 20px;
  }
  &-name {
    color: #000 !important;
    font-style: normal !important;
    font-weight: 500 !important;
    margin-top: 13px !important;
    word-wrap: break-word;
  }
  &-course {
    color: #212121;
    font-style: normal;
    font-weight: 400 !important;
    margin-top: 9px !important;
  }
  &-location,
  &-success-message {
    color: #212121;
    font-style: normal;
    font-weight: 300 !important;
    overflow-wrap: anywhere;
  }
  &-resume {
    color: #212121;
    font-style: normal;
    font-weight: 300 !important;
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
  }
  &-contact-details {
    color: #212121;
    font-style: normal;
    font-weight: 400 !important;
  }
}
.upload-resume {
  display: none;
}
.upload-icon,
.download-icon {
  cursor: pointer;
}
.mobile-phone,
.email-address,
.address,
.linkedin {
  color: #707070 !important;
  font-style: normal !important;
  font-weight: 400 !important;
}
.subtitle {
  color: #212121;
  font-style: normal;
  font-weight: 400 !important;
  word-wrap: break-word;
}

.email {
  color: #212121 !important;
  font-style: normal;
  font-weight: 400 !important;
}
.summary-text {
  color: #212121;
  font-style: normal;
  font-weight: 400 !important;
}

.summary {
  color: #212121 !important;
  font-style: normal;
  font-weight: 200 !important;
  margin-top: 8px !important;
  word-wrap: break-word;
}
.linkedIn-link {
  transition: 0.3s all;
  color: #2a70ea;
  word-wrap: break-word;
}

@media screen and (max-width: 1200px) {
  .vertical-line1-icon {
    display: none;
  }
}
@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .candidate-info .avatar-icon {
    margin-top: -21px;
  }
}
@media screen and (min-width: 1441px) and (max-width: 1600px) {
  .candidate-info .avatar-icon {
    margin-top: -23px;
  }
}
@media screen and (min-width: 1601px) {
  .candidate-info .avatar-icon {
    margin-top: -40px;
  }
}
@media screen and (max-width: 600px) {
  .candidate-info .avatar-icon {
    margin-top: -18px;
  }
}

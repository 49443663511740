.button {
  color: #f0f0f0;
  font-family: 'Public Sans', sans-serif !important;
  font-style: normal;
  font-weight: 600;
  padding: 16px 66px 15.684px 72px;
  justify-content: flex-end;
}
.required {
  color: #e31a1c;
}
.signinButton {
  display: inline-flex;
  padding: 16px 66px 15.684px 72px;
  align-items: flex-start;
  gap: 12px;
  flex-shrink: 0;
}
.resendButton {
  display: flex;
  width: 289px !important;
  padding: 16px 95px 16px 100px;
  align-items: center;
  flex-shrink: 0;
}
.signinButton.Mui-disabled {
  opacity: 0.5;
  cursor: not-allowed;
  color: #000 !important;
}
.signinButton:hover {
  color: #fff !important;
}
.forgotPassword {
  text-decoration: none;
  color: #6993ff !important;
  font-weight: 600;
}

.error {
  color: #c00000;
  font-family: 'Public Sans', sans-serif !important;
  font-style: normal;
  font-weight: 400;
  margin-top: 8px;
}
.loginText {
  color: #1a1e29;
  font-style: normal;
  font-weight: 400 !important;
  
  margin-bottom: 8px !important;
}
.loginText2 {
  color: #707070;
  font-style: normal;
  font-weight: 300;
  margin-top: 4px;
}
.emailInput {
  color: #686868;
  font-family: 'Public Sans', sans-serif !important;
  font-style: normal;
  font-weight: 300;
}
.emailText {
  color: #383838;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.5px;
  margin-bottom: 10px;
}
.resendText {
  color: #707070;
  font-family: 'Public Sans', sans-serif !important;
  font-style: normal;
  font-weight: 300;
  margin-bottom: 16px !important;
}
.resendLink {
  color: #2a70ea;
  font-family: 'Public Sans', sans-serif !important;
  font-style: normal;
  font-weight: 300;
  text-decoration: none;
}

.contact {
  color: #000;
  font-style: normal;
  font-weight: 300 !important;
}

#login-form-box {
  

  @media (max-width: 400px) {
    width: 100%;
  }
  @media (width: 900px) {
    width: 100%;
  }
}
@media (max-width: 900px) {
  .resendButton {
    width: 100% !important;
  }
}
@media screen and (min-width: 700px) and (max-width: 900px) {
  #login-text {
    font-size: 1.5rem;
  }
  #login-info-text {
    font-size: 0.75rem;
  }
  #signin-button,
  #re-send-button {
    font-size: 1rem;
  }
  #enter-email {
    font-size: 1.25rem;
  }
}

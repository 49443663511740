.textInput {
  /* font-size: 12.8px !important; */
  &.warning .MuiOutlinedInput-notchedOutline {
    border-color: orange;
  }
  font-family: 'Inter', sans-serif;
  &.error .MuiOutlinedInput-notchedOutline {
    border-color: red;
  }

  &.multiline {
    padding: 8px;
  }
}

.textInputLabel {
  &.Mui-focused {
    color: black;
  }
}

.textInputHelperText {
  &.warning {
    color: orange;
  }
  &.error {
    color: red;
  }
}

.passwordIcon {
  cursor: pointer;
}

.errorIcon {
  color: red;
}

.warningIcon {
  color: orange;
}
.TextInputField {
  border-color: #d9d9d9;
  color: #686868;
  border-radius: 0.42rem !important;
  transition:
    color 0.15s ease,
    background-color 0.15s ease,
    border-color 0.15s ease,
    box-shadow 0.15s ease;
}

body {
  margin: 0;
  font-family: 'Public Sans', sans-serif;
  background: #f6f6f6;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

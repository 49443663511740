.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.formLabel {
  font-family: 'Public Sans', sans-serif !important;
  color: #000 !important;
}

.required {
  color: #2a70ea !important;
}

.error_message {
  font-family: 'Public Sans', sans-serif !important;
  font-size: 0.7rem !important;
  color: #e31a1c;
}

.sub-route-section {
  margin: 2% !important;
  padding: 1% !important;
  background-color: #ffffff !important;
}
.sub-routes-header {
  font-family: 'Public Sans', sans-serif !important;
  color: #000 !important;
  font-weight: 400 !important;
  display: inline-block !important;
}

.sub-routes-headerFS25 {
  font-family: 'Public Sans', sans-serif !important;
  color: #000 !important;
  font-weight: 400 !important;
  display: inline-block !important;
}

.sub-tab-header-section {
  margin: 0 -1vw !important;
  border-bottom: 1px solid #e9e9e9 !important;
}

.blue-text {
  color: #2a70ea !important;
  font-family: 'Public Sans', sans-serif !important;
  font-weight: 600 !important;
}
.banner {
  padding-left: 40px;
  position: relative;
  margin-bottom: 20px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  flex: 1;
}
.banner-heading {
  color: #fff !important;
  font-style: normal !important;
  font-weight: 400 !important;
  z-index: 1;
  position: relative;
}
.banner-info {
  color: #fff !important;
  font-style: normal;
  font-weight: 300 !important;
  margin-top: 11px !important;
  z-index: 1;
  position: relative;
  width: 40%;
}
@media screen and (max-width: 900px) {
  .sub-tab-header-section {
    margin: 0 0 !important;
    border-bottom: 1px solid #e9e9e9 !important;
  }
  .banner-info {
    width: 100%;
  }
}

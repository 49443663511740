.MuiGrid-root {
  .navigationGuard-title {
    color: #000;
    font-weight: 400;
  }
  .bodyText {
    color: #212121;
    text-align: center;
    font-weight: 200;
  }
}

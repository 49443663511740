.contact-preference {
  padding: 11px 20px 20px 7px;
  &-text {
    color: #212121;
    font-style: normal;
    font-weight: 200 !important;
  }
  .note {
    color: #e31a1c;
    font-style: normal;
    font-weight: 500;
  }
  .note-info {
    color: #212121;
    font-style: normal;
    font-weight: 200;
  }
  .contact-type {
    color: #383838;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0.5px;
  }
  .contact-option {
    color: var(--concrete, #919191);
    font-family: 'Public Sans';
    font-style: normal;
    font-weight: 300;
  }
  .active-switch {
    color: var(--Black, #131416);
  }
}

.loginContainer {
  height: 100vh;
  display: flex;
  flex-direction: row;
}
.imageContainer {
  background-image: url('../images/office.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  flex: 1;
}

.item1 {
  /* background-image: url('../images/login-image.png') !important; */
  background-size: cover;
  height: 100vh;
  position: relative;
}
.candidateLogin {
  background-image: url('../images/login_image.jpg') !important;
}
.clientLogin {
  background-image: url('../images/Client-login-2.jpg') !important;
}
.item2 {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24px;
}
.imageText1 {
  color: var(--Schemes-On-Primary, #fff);
  font-family: 'Public Sans', sans-serif !important;
  font-style: normal;
  font-weight: 300 !important;
  line-height: 1.2;
  margin-bottom: 0.5rem !important;
}
.imageText2 {
  color: var(--Schemes-On-Primary, #fff);
  font-family: 'Public Sans', sans-serif !important;
  font-style: normal !important;
  font-weight: 300 !important;
}
.rectangle1 {
  position: absolute;
  bottom: 152px;
  right: 0px;
}
.rectangle2 {
  position: absolute;
  bottom: 0px;
  right: 197px;
}

@media screen and (max-width: 992px) {
  /* Styles for screens wider than or equal to 768px */
  .imageContainer {
    // display: none;
  }
}
@media screen and (max-width: 900px) {
  .item1 {
    // display: none;
  }
}

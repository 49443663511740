.button {
  border-radius: 0px !important;
  padding: 5px 20px;
  box-shadow: none;
  font-weight: 500 !important;
  /* font-size: 12.8px !important; */
  text-transform: none !important;
  font-family: 'Public Sans', sans-serif !important;
}

.buttonPrimaryDisabled {
  border-radius: 0px !important;
  color: #ffffff !important;
  opacity: 0.5;
}

.buttonSecondaryDisabled {
  border: 1px solid #d9d9d9 !important;
  opacity: 0.5;
}

.hover {
  box-shadow: none;
}
.primary {
  background-color: #2a70ea !important;
  box-shadow: none !important;
} /* Blue */
.secondary {
  border: 1px solid #d9d9d9 !important;
  color: #212121 !important;
  background-color: #ffffff !important;
  box-shadow: none !important;
} /* White */
.success {
  background-color: #4caf50 !important;
} /* Green */
.error {
  background-color: #f44336 !important;
} /* Red */
.warning {
  background-color: #ff9800 !important;
} /* Orange */
.info {
  background-color: #2196f3 !important;
} /* Light Blue */
/* @media (min-width: 1537px) {
  .button {
    font-size: 16px !important;
  }
} */

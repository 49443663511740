.najaxa-iframe {
  position: relative;
  height: 90vh;

  &-content {
    width: 100%;
    height: 100%;
    position: absolute;
    border: none;
    left: 0;
  }
}

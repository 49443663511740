.delete-skill {
  padding: 0rem;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 8px;
  background-color: white;

  .delete-skill-text {
    font-weight: 400;
    color: black;
  }
  .top-left-typographies,
  .top-typographies {
    font-weight: 200;
    text-align: center;
  }
  .top-left-typographies {
    margin-top: 15px;
  }

  .top-typographies {
    margin-top: 10px;
  }
}
